<template>
  <div class="home-view" contenteditable="" spellcheck="false">
    <img width="201" src="@/assets/images/logo.png">
    <h1 style="margin-bottom:5px">Size Özel Fiyat Teklifi</h1>
    <p style="margin-top:0px">İlk görüşmemize özel aktarılan fiyat teklifidir, fiyatlar ileri zamanlarda değişiklik gösterebilir.</p>
    <div class="container">
        <div class="row">
          <div class="col">
            <table class="business">
              <thead>
                <th>Ürün</th>
                <th>Adet</th>
                <th>Tutar</th>
              </thead>
              <tbody>
                <tr>
                  <td><b class="title">Promosyon Tasarımı</b></td>
                  <td><span>10 Adet Set</span></td>
                  <td><b class="amount">45 $</b></td>
                </tr>
                <tr>
                  <td><b class="title">Banner Tasarımı</b></td>
                  <td><span>10 Adet Set</span></td>
                  <td><b class="amount">45 $</b></td>
                </tr>
                <tr>
                  <td><b class="title">Anasayfa Tasarım</b></td>
                  <td><span>Çizim ve Kodlama</span></td>
                  <td><b class="amount">200 $</b></td>
                </tr>
                <tr>
                  <td><b class="title">Animasyon Banner</b></td>
                  <td><span>3 Adet</span></td>
                  <td><b class="amount">100 $</b></td>
                </tr>
                <tr>
                  <td><b class="title">Popup</b></td>
                  <td><span>5 Adet</span></td>
                  <td><b class="amount">50 $</b></td>
                </tr>
                <tr>
                  <td><b class="title">Aylık Hizmet</b></td>
                  <td><span>Tasarımsal ihtiyaçlar</span></td>
                  <td><b class="amount">400 $</b></td>
                </tr>
                <tr>
                  <td><b class="title">Tekli Hizmet</b></td>
                  <td><span>Sitenizin tüm tasarımsal ihtiyaçları</span></td>
                  <td><b class="amount">400 $</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>
    <br>
  </div>
</template>

<script>
export default{
  data(){
    return{
    }
  },
  components:{
  }
}
</script>