<template>
  <div class="home-view">
    <div class="container">
      <div class="img-col">
        <router-link to="/" ><img width="201" src="@/assets/images/logo.png"></router-link>
      </div>
      <div class="modal" v-if="!isLogin">
        <div class="content">
          <label class="label">Giriş Şifre</label>
          <input placeholder="Şifre" type="text" v-model="pass">
          <button @click="login" class="btn btn-primary">Giriş Yap</button>
        </div>
      </div>
      <div class="container row-col">
        <div class="panel" style="padding:20px" v-show="!hide" >
            <div class="buttons">
              <div class="btn" @click="setCard('card')" :class="activeCard == 'card' ? 'btn-primary' : 'btn-default'">Banner Cards</div>
              <div class="btn" @click="setCard('upcoming')" :class="activeCard == 'upcoming' ? 'btn-primary' : 'btn-default'">Upcoming Events</div>
              <div class="btn" @click="setCard('video')" :class="activeCard == 'video' ? 'btn-primary' : 'btn-default'">Videos</div>
              <div class="btn" @click="setCard('system')" :class="activeCard == 'system' ? 'btn-primary' : 'btn-default'">System</div>
              <div class="btn" @click="setCard('landings')" :class="activeCard == 'landings' ? 'btn-primary' : 'btn-default'">Landings</div>
            </div>
        </div>
        <div class="panel" style="padding:20px" v-show="hide">
            <div class="buttons">
              <div class="btn btn-primary">
                <slot v-if="activeCard == 'card'">Bannerlar</slot>
                <slot v-if="activeCard == 'upcoming'">Günün Karşılaşmaları</slot>
                <slot v-if="activeCard == 'video'">Video Tasarımları</slot>
                <slot v-if="activeCard == 'system'">Sistemler</slot>
                <slot v-if="activeCard == 'landings'">Landing Tasarımları</slot>
              </div>
            </div>
        </div>
        <slot v-if="isLogin">
          <div class="content" style="text-align:center" v-if="activeCard == 'landings'">
            <div class="row" style="flex-wrap:wrap">
              <div class="col col-6"> 
                <div class="item-card">
                  <video controls>
                    <source src="@/assets/images/landings/bonanza.mp4" type="video/mp4">
                  </video>
                  <p>Sweet Bonanza HTML5 Landing Page</p>
                  <span>Mobil Uyumludur.</span>
                  <span>İşletmenize ait logo ve renk ile özelleştirilir.</span>
                  
                </div>
              </div>
              <div class="col col-6">
                <div class="item-card">
                  <video controls>
                    <source src="@/assets/images/landings/gool.mp4" type="video/mp4">
                  </video>
                  <p>Gates Of Olympus HTML5 Landing Page</p>
                  <span>Mobil Uyumludur.</span>
                  <span>İşletmenize ait logo ve renk ile özelleştirilir.</span>
                </div>
              </div>
            </div>
          </div>
          <div class="content" style="text-align:center" v-if="activeCard == 'card'">
            <div class="row" style="flex-wrap:wrap">
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/Tc4Bm9NUXpSvWTfh.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/F4F8APm8zSKQFyST.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/d9RQBydmVph6f6CS.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/dDZdS3jWgFRPuwdt.jpg" width="100%">
                </div>
              </div>
              <div class="col col-12">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/1677069576_slider.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/e5crypzLGF9Cu2kD.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/q8zGbX67vRdjwnkW.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/gk5cXq6e9uRAKrnH.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/J3A2hvgjUHNwutfA.jpg" width="100%">
                </div>
              </div>
              <div class="col col-12">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/1677067517_slider.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/KbP2ruE2C66hxnJp.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/NcqKzHCjPj387anX.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/Q3sfGhLCE5M6tahZ.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/sEuJ43S4m7CcTfxc.jpg" width="100%">
                </div>
              </div>
              <div class="col col-12">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/1677155168_slider.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/XSLYuLvZFne9LHpN.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/L8Yfj9qQPTX5q3Aa.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/wfPZ4vRLkun35XLG.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/3bckAz9eASncWhgz.jpg" width="100%">
                </div>
              </div>
              <div class="col col-4">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/9Use78qLRSUkZzvn.jpg" width="100%">
                </div>
              </div>
              <div class="col col-4">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/bYd7hRhbcrYrLPHN.jpg" width="100%">
                </div>
              </div>
              <div class="col col-4">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/ggVf96hjBhJ2YSZh.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/ReesE9Q3C6ytTp9k.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/sq4AyGP2Q9DWG5jv.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/9g5TDkyAeewPmZeq.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/Tqz8M2tjLqTvc6UM.jpg" width="100%">
                </div>
              </div>
              <div class="col col-12">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/F9dA6JAKeQGKNsuc.jpg" width="100%">
                </div>
              </div>
              <div class="col col-6">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/fGUhSND6ZJ4XwmMk.jpg" width="100%">
                </div>
              </div>
              <div class="col col-6">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/M3JrzNHrX9xF3wJ3.jpg" width="100%">
                </div>
              </div>
              <div class="col col-6">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/n8cBKQmENM3jCXwz.jpg" width="100%">
                </div>
              </div>
              <div class="col col-6">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/3hGNDAsCTbn7ggRq.webp" width="100%">
                </div>
              </div>
              <div class="col col-6">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/HszPb58MR63dvtHZ.jpg" width="100%">
                </div>
              </div>
              <div class="col col-6">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/VEPFfxDfCTF5rFdN.jpg" width="100%">
                </div>
              </div>
            </div>
          </div>
          <div class="content" style="text-align:center" v-if="activeCard == 'upcoming'">
            <div class="row" style="flex-wrap:wrap">
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/upcoming/uWmF5YtehCmTReqJ.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/upcoming/UBrSTpnVq7yeevdM.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/upcoming/typNf6dLqfpwCAZq.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/upcoming/7WcRPCYjqxX9RtHe.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/upcoming/XYKrdJdbzwv8vKun.jpg" width="100%">
                </div>
              </div>
              <div class="col col-3">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/upcoming/3njXXArDdJWDBugS.jpg" width="100%">
                </div>
              </div>
              <div class="col col-12">
                <div class="item-card">
                  <img style="border-radius:10px" src="@/assets/images/ref/upcoming/mhAA4UVY9uUGpT9n.jpg" width="100%">
                </div>
              </div>
            </div>
          </div>
          <div class="content" style="text-align:center" v-if="activeCard == 'video'">
            <div class="row" style="flex-wrap:wrap">
              <div class="col col-4">
                <div class="item-card">
                  <video controls>
                    <source src="@/assets/images/ref/videos/pAt2hy3qHpvgsetj.mp4" type="video/mp4">
                  </video>
                </div>
              </div>
              <div class="col col-4">
                <div class="item-card">
                  <video controls>
                    <source src="@/assets/images/ref/videos/uHv8Rv3Ym64Ln765.mp4" type="video/mp4">
                  </video>
                </div>
              </div>
              <div class="col col-4">
                <div class="item-card">
                  <video controls>
                    <source src="@/assets/images/ref/videos/5nb3dEMJny7eSrhd.mp4" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
          </div>
          <div class="content" style="text-align:center" v-if="activeCard == 'system'">
            <div class="row" style="flex-wrap:wrap">
              <div class="col col-4">
                <div class="item-card">
                  <a href="https://carkifelek.thepapelbethediye.com/" target="_blank"><img src="@/assets/images/ref/system/eSxACAMNnaRudh9E.png" width="100%"></a>
                  <p>Çarkıfelek Sistemi</p>
                  <span>Mobil Uyumludur.</span>
                  <span>İşletmenize ait logo ve renk ile özelleştirilir.</span>
                </div>
              </div>
              <div class="col col-4">
                <div class="item-card">
                  <a href="https://kazikazan.thepapelbethediye.com/" target="_blank"><img src="@/assets/images/ref/system/43qMChXnMVURZs9K.png" width="100%"></a>
                  <p>Kazıkazan (Kartı Bul) Sistemi</p>
                  <span>Mobil Uyumludur.</span>
                  <span>İşletmenize ait logo ve renk ile özelleştirilir.</span>
                </div>
              </div>
              <div class="col col-4">
                <div class="item-card">
                  <img src="@/assets/images/ref/system/4c2FbsXPwMQTJd7t.png" width="100%">
                  <p>Bonus Talep Sistemi</p>
                  <span>Mobil Uyumludur.</span>
                  <span>İşletmenize ait logo ve renk ile özelleştirilir.</span>
                </div>
              </div>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
  
  export default{
    data(){
      return{
        activeCard: 'card',
        hide: false,
        pass:null,
        isLogin: false,
      }
    },
    created(){
      if(this.$route.query.card){
        this.activeCard = this.$route.query.card
        this.hide = true;
      }
    },
    methods:{
      setCard(id){
        this.activeCard = id;
        this.$router.replace({query:{ card: id }})
      },
      login(){
        if(this.pass == '7tz8Azct7YT2HNAp'){
          this.isLogin = true;
        }
      }
    }
  }
</script>