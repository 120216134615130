import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMoment from 'vue-moment'
import VueAwesomeSwiper from 'vue-awesome-swiper';
import Notifications from 'vue-notification';

window.axios = require('axios');


Vue.use(VueAwesomeSwiper);


Vue.prototype.$pageMeta = function($meta) {
  return _.get(store.getters['getInit'], 'general.site_name');
}
const moment = require('moment')
require('moment/locale/tr')
Vue.use(require('vue-moment'), {moment})
window._ = require("lodash");
Vue.prototype._ = window._;


Vue.use(Notifications);

import './assets/scss/app.scss'

import { isMobile } from 'mobile-device-detect';
import './registerServiceWorker'
Vue.prototype.isMobile = isMobile

new Vue({
  router,
  store,
  duplicateNavigationPolicy: 'ignore',
  mounted () 
  {
  },
  render: function (h) { return h(App) }
}).$mount('#app')
