import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import Business from '../views/Business'
import Alarm from '../views/Alarm'

import Designs from '../views/Designs/index'
import Templates from '../views/Designs/template'
import Banner from '../views/Designs/banner'
import Other from '../views/Designs/other'
import Animation from '../views/Designs/animation'
import Promotion from '../views/Designs/promotion'
import Landings from '../views/Designs/landings'

Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta:{
      auth: false,
    },
  },
  {
    path: "/alarm",
    name: "Alarm",
    component: Alarm,
    meta:{
      auth: false,
    },
  },
  {
    path: "/landings",
    name: "Landings",
    component: Landings,
    meta:{
      auth: false,
    },
  },
  {
    path: "/designs",
    redirect: "/designs/template",
    name: "Designs",
    component: Designs,
    children: [
      {
        path: "template",
        name: "Templates",
        component: Templates,
      },
      {
        path: "landings",
        name: "Landings",
        component: Landings,
      },
      {
        path: "references",
        name: "Banner",
        component: Banner,
      },
      {
        path: "promotion",
        name: "Promotion",
        component: Promotion,
      },
      {
        path: "animation",
        name: "Animation",
        component: Animation,
      },
      {
        path: "other",
        name: "Other",
        component: Other,
      },
    ]
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  behavior: 'smooth',
  linkActiveClass: "active",
  routes
});

export default router
