import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules';
//import router from '../router'

Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		init: {},
		ready: false,
		languages:['tr','en'],
	},
	getters:{
		getLanguages(state) {
			return state.languages;
		},
		getInit(state){
			return state.init
		},
		getReady(state){
			return state.ready
		}
	},
	mutations: {
		setInit(state,val){
			state.init = val
		},
		setReady(state,val){
			state.ready = val
		}
	},
	actions: {
		initClient({ commit, dispatch, state }) {
			return axios.get('init')
				.then((response) => {
					commit('setInit', response.data.data)
					commit('setReady', true);
					document.querySelector("body").style.setProperty('--background', state.init.colors.background);
					document.querySelector("body").style.setProperty('--alert-color', state.init.colors.alert_background);
					document.querySelector("body").style.setProperty('--boxes-color', state.init.colors.boxes);
					document.querySelector("body").style.setProperty('--panel-color', state.init.colors.panel);
					document.querySelector("body").style.setProperty('--color', state.init.colors.first_button);
					document.querySelector("body").style.setProperty('--button-color', state.init.colors.first_button_font);
					document.querySelector("body").style.setProperty('--color-2', state.init.colors.second_button);
					document.querySelector("body").style.setProperty('--button-2-color', state.init.colors.second_button_font);
					document.querySelector("body").style.setProperty('--color-3', state.init.colors.third_button);
					document.querySelector("body").style.setProperty('--button-3-color', state.init.colors.third_button_font);
					document.querySelector("body").style.setProperty('--input-color', state.init.colors.input);
					document.querySelector("body").style.setProperty('--input-font', state.init.colors.input_font);
					document.querySelector("body").style.setProperty('--input-border', state.init.colors.input_border);
					document.querySelector("body").style.setProperty('--button-3-color', state.init.colors.third_button_font);
					document.querySelector("body").style.setProperty('--header', state.init.colors.header_background);
					document.querySelector("body").style.setProperty('--header-color', state.init.colors.header_font);
					document.querySelector("body").style.setProperty('--header-border', state.init.colors.header_border);
					document.querySelector("body").style.setProperty('--footer', state.init.colors.footer_background);
					document.querySelector("body").style.setProperty('--footer-color', state.init.colors.footer_font);
					document.querySelector("body").style.setProperty('--footer-border', state.init.colors.footer_border);
				})	
				.catch((err) => {
				});
		},
	},
	modules: modules
})
