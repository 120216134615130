<template>
  <div id="app" :class="[{'mobile' : isMobile}]">
      <div class="inc">
        <notifications/>
        <router-view/>
      </div>
  </div>
</template>

<script>
  export default{
    data(){
      return{
      }
    },
    created(){

    }
  }
</script>
