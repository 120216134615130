<template>
  <div class="fast-access">
    <img src="@/assets/images/logo.png" style="width:150px">
    <img src="@/assets/images/alarm.gif" style="width:75px;margin:20px 0">
    <p>Ulaşamadığınız takdirde bizlere alarm gönderebilirsiniz.</p>
    <div :disabled="loading" class="btn btn-primary" @click="alarm" >
      <slot v-if="!loading">
        <svg viewBox="0 0 32 32"> <g id="pointer2" > <g data-name="Group 2338" transform="translate(-468 -260)"> <g id="Group_2334" data-name="Group 2334"> <g id="Group_2333" data-name="Group 2333"> <g id="Group_2332" data-name="Group 2332"> <path id="Path_3837" data-name="Path 3837" d="M491.8,265.339a5.753,5.753,0,0,0-8.126,0,.5.5,0,0,0,.707.707,4.746,4.746,0,1,1,6.712,6.712.5.5,0,0,0,.707.707A5.753,5.753,0,0,0,491.8,265.339Z" /> </g> </g> </g> <g id="Group_2337" data-name="Group 2337"> <g id="Group_2336" data-name="Group 2336"> <g id="Group_2335" data-name="Group 2335"> <path id="Path_3838" data-name="Path 3838" d="M494.347,262.8a9.342,9.342,0,0,0-14.968,10.771l-.6.6a4.462,4.462,0,0,0-2.6.125,8.876,8.876,0,0,0-4.753,5.031,9.766,9.766,0,0,0,.6,7.577,8.609,8.609,0,0,0,5.285,4.77,7.112,7.112,0,0,0,1.938.268,7.2,7.2,0,0,0,3.89-1.142,41.143,41.143,0,0,0,6.372-5.485,2.664,2.664,0,0,0,.778-2.01,2.349,2.349,0,0,0-.85-1.723l-.068-.053a2.592,2.592,0,0,0-.363-2.881,9.327,9.327,0,0,0,5.334-15.848Zm-6.266,21.123a40.074,40.074,0,0,1-6.017,5.194,5.181,5.181,0,0,1-4.206.632,6.47,6.47,0,0,1-4.026-3.716,7.788,7.788,0,0,1-.523-6.029,6.812,6.812,0,0,1,3.345-3.708l-1.161,1.161a1,1,0,1,0,1.414,1.414l2.882-2.881v0l7.3-7.3a.993.993,0,0,1,1.369,0,.968.968,0,0,1,0,1.368l-6.228,6.229a1,1,0,1,0,1.414,1.414l.95-.95a.945.945,0,0,0,.132-.088.831.831,0,0,1,1.148,0,.809.809,0,0,1,.238.574.843.843,0,0,1-.271.607l-.684.751a1,1,0,0,0,1.478,1.347l.009-.009.005,0a.593.593,0,0,1,.866.809l-.939.76a2.9,2.9,0,0,0-.56.466,1,1,0,0,0,1.363,1.456l.376-.3a.366.366,0,0,1,.42.011.355.355,0,0,1,.122.274A.7.7,0,0,1,488.081,283.92Zm-.019-7.19a2.789,2.789,0,0,0-.775-1.484,2.645,2.645,0,0,0-.7-.488l3.288-3.288a2.968,2.968,0,0,0,0-4.2,3.039,3.039,0,0,0-4.2,0l-4.779,4.779a7.35,7.35,0,1,1,7.167,4.678Z" /> </g> </g> </g> </g> </g> </svg>
      Alarm Gönder !
      </slot>
      <slot v-else>
        <div class='threedotloader'>
          <div class='dot'></div>
          <div class='dot'></div>
          <div class='dot'></div>
        </div>
      </slot>
    </div>
    <br>
    <p style="font-size:12px; opacity:0.5">Alarm gönderdikten 5 dakika sonra dönüş sağlanılmazsa tekrar alarm gönderebilirsiniz.</p>
  </div>
</template>

<script>
  import axios, * as others from 'axios';
  export default{
      data(){
        return{
          desc: 'Siteden Bildirim Geldi!',
          loading:false,
          again:1,
        }
      },
      created(){
      },
      methods:{
        alarm(){
          this.loading = true;
          setTimeout(() => {
            axios.get('https://pay.paydev.net/bildirim?site=' + this.desc)
              .then((response)=>{
                  this.loading = false;
                  this.again = this.again + 1;
                  this.$notify({ type: 'success', text: 'Alarm gönderildi. 5 Dakika sonra dönüş sağlanılmazsa tekrar alarm gönderebilirsiniz.' })
              })
              .catch((err)=>{
                  
              })
          }, 5000 * this.again);
        },
      }
  }
</script>
